import { setUploadedBanners } from './../actions/projectFolders';
import { AddMeta, ADD_META, DeleteMeta, DELETE_META, FetchDesignMeta, FETCH_DESIGN_META, SEARCH_META, UpdateProjectDescription, UPDATE_PROJECT_DESCRIPTION } from './../types/projectFolders';
import { SET_SELECTED_PRODUCT_ID } from './../types/roles';
import { designMetaAPI, designMetaRecommendataionAPI, projectDescription, tagsAPI, tagsRecommendationAPI } from './../../MappedAPI/MappedAPI';
import { put, takeLatest, all, call, takeEvery, select } from 'redux-saga/effects';
import axios from 'axios';
import { DESIGN_URI, IMAGE_BASE_URL, AUDIT_URI, ENV_TYPE, S3_BASE_URL } from '../../utilities/paths';
import { setActiveFolder, addFolder, setProject, setProjects, getStatusSummary, setFolders, setSelectedFolders, setSelectedProject, setFoldersPageData, getUsersData } from '../actions';
import HttpService from '../../HttpService/HttpService';
import {
    FETCH_FOLDERS, FetchFoldersAction, AddNewFolderAction, ADD_NEW_FOLDER, MoveFolderAction,
    MOVE_FOLDER, DeleteFolderAction, DELETE_FOLDER, UPDATE_FOLDER, UpdateFolderAction, FETCH_PROJECTS,
    FetchProjectsAction,
    FetchProjectAction,
    FETCH_PROJECT,
    Banner,
    BannerData,
    DeleteProjects,
    DELETE_PROJECTS,
    MOVE_PROJECTS,
    MoveProjects,
    FetchFilesAction,
    FETCH_FILES,
    MOVE_FILES,
    MoveFiles,
    DELETE_FILES,
    DeleteFiles,
    ADD_PROJECT_TO_TEMPLATE,
    AddProjectToTemplate,
    Project,
    UploadLibraryFile,
    UPLOAD_LIBRARY_FILE,
    FetchHyperProjects,
    FETCH_HYPER_PROJECTS,
    FetchAllFiles,
    FETCH_ALL_FILES,
    DuplicateProject,
    DUPLICATE_PROJECT,
    FetchLibraryFile,
    FETCH_LIBRARY_FILE,
    DeleteShareableLink,
    DELETE_SHAREABLE_LINK,
    FetchTags,
    FETCH_TAGS,
    GenerateShareableLink,
    GENERATE_SHAREABLE_LINK,
    FETCH_PROJECT_FROM_SHAREABLE_LINK,
    FetchProjectFromShareableLink,
    AddOrDeleteTag,
    GetShareableLink,
    SearchTags,
    GET_SHAREABLE_LINK,
    ADD_OR_DELETE_TAG,
    SEARCH_TAGS,
    GET_TOKEN_FROM_SHAREABLE_LINK,
    GetTokenFromShareableLink,
    FileToProject,
    FILE_TO_PROJECT,
    UpdateFileToProject,
    UPDATE_FILE_TO_PROJECT,
    UpdateVariant,
    UPDATE_VARIANT,
    CreateVariant,
    CREATE_VARIANT,
    DeleteVariant,
    DELETE_VARIANT,
    CreateFolderSutructure,
    CREATE_FOLDER_STRUCTURE,
    CancelFileToProject,
    GetQuickViewBucket,
    GET_QUICK_VIEW_BUCKET,
    FileUploader,
    FILE_UPLOADER,
    UploadToS3,
    UPLOAD_TO_S3,
    ProjectUpload,
    PROJECT_UPLOAD
} from '../types';
import { projectAPI, hyperProjectsAPI, uploadFileAPI, getSubFoldersAPI } from '../../MappedAPI/MappedAPI';
import { linkOpened } from '../../../src/utilities/services/mixpanel';
import { openToast } from '../../Toasts';
import { detectQueryString, findRatio } from '../../utilities/common-function';
// Token to abort API on close of UploadDesignPopUp
let fileToProjectToken = axios.CancelToken.source();

const getToken = (state: any) => state.authentication.token;
const getTokenOrPublic = (state: any) => (!window.location.hash.startsWith('#/cocreate/public') ? state.authentication.token : state.publicLink.token);
const getProductId = (state: any) => state.roles.selectedProductId;
const getFoldersState = (state: any) => state.folders;

function* getFolders(action: FetchFoldersAction): any {
    let { payload: { parent, type, created_at_start, created_at_end, search, created_by, order_by }, callback } = action;
    const TOKEN = yield select(getTokenOrPublic);
    const product_id = yield select(getProductId);

    try {
        let resultArr: any[] = [];
        let ids: number[] = parent ? parent : [0];
        const order_by_modified = order_by?.includes("title") ? order_by?.replace("title", "name") : order_by
        let result: any = {};
        for (const id of ids) {
            let query = `${DESIGN_URI}/api/folder?${!!id ? ('parent=' + id + '&') : ''}type=${type}&product_id=${product_id}${order_by ? "&order_by=" + order_by_modified : ""}${created_at_start ? "&created_at_start=" + created_at_start : ""}${created_at_end ? "&created_at_end=" + created_at_end : ""}${search ? "&search=" + encodeURIComponent(search) : ""}${created_by ? "&created_by=" + created_by : ""}`;
            let response = yield axios.get(query, {
                headers: {
                    token: TOKEN,
                }
            })
            if (!response.data.error) {
                let { data: { data: { results } } } = response;
                results.forEach((data: any) => { data["parent"] = id ? id : 0 });
                result = response.data.data;
                resultArr = [...resultArr, ...results];
            }
        }
        if (!parent) {
            yield put(setActiveFolder(0))
        }
        if (callback) {
            callback({ results: resultArr, parent: result.parent }, false);
        } else {
            yield put(addFolder(resultArr));
        }
    }
    catch (error) {
        callback && callback(error?.response?.data, true);
        openToast('error', error?.response?.data?.message || "Something went wrong!")
    }
}


function* addNewFolder(action: AddNewFolderAction) {
    let { payload: { name, parent, type }, callback } = action;
    const TOKEN: any = yield select(getToken);
    const product_id: any = yield select(getProductId);
    if (!parent) {
        delete action.payload.parent;
    }
    try {
        let query = `${DESIGN_URI}/api/folder/new`;
        let response = yield axios.post(query, { ...action.payload, product_id },
            {
                headers: {
                    token: TOKEN
                }
            }
        );
        if (!response.data.error) {
            callback && callback(response.data, false);
            yield all([
                put(addFolder([{ ...response.data.data, parent: action.payload.parent ? action.payload.parent : 0 }]))
            ])
            openToast('success', response?.data?.data?.message || "New folder added!")
        } else {
            callback && callback(response, true);
        }
    }
    catch (error) {
        callback && callback(error.response, true);
        openToast('error', error?.response?.data?.message || "Something went wrong!")
    }
}


function* moveFolder(action: MoveFolderAction) {
    let { payload: { selectedFolders, destinationFolderId }, callback } = action;
    const TOKEN = yield select(getToken);
    const product_id = yield select(getProductId);
    try {
        let query = `${DESIGN_URI}/api/folder/move`;
        let response = yield axios.post(query, { folder_ids: selectedFolders, destination_folder_id: destinationFolderId === 0 ? undefined : destinationFolderId, product_id },
            {
                headers: {
                    token: TOKEN
                }
            }
        );
        if (!response.data.error) {
            callback && callback(response.data, false);
            // let data = yield select(getFoldersState);
            // data.folders.forEach((folder:any)=>{
            //     if(action.payload.selectedFolders.indexOf(folder.id) > -1){
            //         folder['parent'] = action.payload.destinationFolderId;
            //     }
            // })
            // openToast('success',response?.data?.data?.message || "Folder Moved!")
            // yield put(setFolders(data.folders))
            // yield put(setSelectedFolders([]))
        } else {
            callback && callback(response, true);
        }
    }
    catch (error) {
        callback && callback(error.response, true);
        openToast('error', error?.response?.data?.message || "Something went wrong!")
    }
}

function* deleteFolder(action: DeleteFolderAction) {
    let { payload, callback } = action;
    const TOKEN = yield select(getToken);
    try {
        let query = `${DESIGN_URI}/api/folder/${action.payload}`;
        let response = yield axios.delete(query,
            {
                headers: {
                    token: TOKEN
                }
            }
        );
        if (!response.data.error) {
            callback && callback(response.data, false);
            let data = yield select(getFoldersState);
            let folders = data.folders.filter((folder: any) => (action.payload !== (folder.id)))
            yield all([
                put(setFolders(folders)),
                put(setSelectedFolders([]))
            ])
            openToast('success', response?.data?.data?.message || "Folder deleted!")
        } else {
            callback && callback(response, true);
        }
    }
    catch (error) {
        callback && callback(error.response, true);
        openToast('error', error?.response?.data?.message || "Something went wrong!")
    }
}

function* updateFolder(action: UpdateFolderAction) {
    let { payload: { id, name }, callback } = action;
    const TOKEN = yield select(getToken);
    try {
        let query = `${DESIGN_URI}/api/folder/${id}`;
        let response = yield axios.put(query, { name },
            {
                headers: {
                    token: TOKEN
                }
            }
        );
        if (!response.data.error) {
            callback && callback(response.data, false);
            let data = yield select(getFoldersState);
            let index = data.folders.findIndex((obj: any) => obj.id == action.payload.id);
            data.folders[index] = action.payload;
            yield all([
                put(setFolders(data.folders))
            ])
            openToast('success', response?.data?.data?.message || "Folder name updated!")
        } else {
            callback && callback(response, true);
        }
    }
    catch (error) {
        callback && callback(error.response, true);
        openToast('error', error?.response?.data?.message || "Something went wrong!")
    }
}


function* fetchProjects(action: FetchProjectsAction) {
    let { payload: { page, page_size, folder_id, project_id, created_at_start, created_at_end, approved_at_end, approved_at_start, statuses, search, created_by, approved_by, tags, order_by }, callback } = action;
    const TOKEN = yield select(getTokenOrPublic);
    const product_id = yield select(getProductId);
    try {
        let params = `?${page ? "page=" + page : ""}&page_size=${page_size}&${folder_id ? "folder=" + folder_id : ""}${project_id ? Array.isArray(project_id) ? "&project_ids=" + project_id.join(',') : "&project_id=" + project_id : ""}&product_id=${product_id}${created_at_start ? "&created_at_start=" + created_at_start : ""}${created_at_end ? "&created_at_end=" + created_at_end : ""}${approved_at_start ? "&approved_at_start=" + approved_at_start : ""}${approved_at_end ? "&approved_at_end=" + approved_at_end : ""}${statuses ? "&statuses=" + statuses : ""}${search ? "&search=" + encodeURIComponent(search) : ""}${created_by ? "&created_by=" + created_by : ""}${approved_by ? "&approved_by=" + approved_by : ""}${tags ? "&tags=" + tags : ""}${order_by ? "&order_by=" + order_by : ""}`;
        let response = yield call(HttpService.get, DESIGN_URI, `${projectAPI}${params}`, TOKEN)
        if (!response.data.error) {
            let { results } = response.data.data;
            let bannerArr: Banner[] = [];
            results.forEach((data: Banner) => {
                bannerArr.push({
                    ...data, ...{
                        parent: folder_id ? folder_id : 0,
                        // rendered_file: IMAGE_BASE_URL + data.rendered_file
                        rendered_file: IMAGE_BASE_URL + data.rendered_file + (data.file_type !== 'gif' ? (detectQueryString(data.rendered_file) ? '&q=40' : '?q=40') + '&auto=format' : ''),
                    }
                })
            })
            response.data.data["data"] = bannerArr;
            callback && callback(response.data.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error) {
        callback && callback(error.response, true);
    }
}
// function* fetchProjects(action: FetchProjectsAction){
//     let { payload: {page,page_size,folder_id,project_id,created_at_start,created_at_end,approved_at_end,approved_at_start,statuses,search,created_by,approved_by,tags,order_by},callback } = action;
//     const TOKEN:any = store.getState().authentication.token;
//     const product_id = store.getState().roles.selectedProductId;
//     try{
//         let params = `?${page? "page="+page: ""}&page_size=${page_size}&${folder_id? "folder="+folder_id: ""}${project_id?Array.isArray(project_id)? "&project_ids=" + project_id.join(','): "&project_id=" + project_id :""}&product_id=${product_id}${created_at_start? "&created_at_start="+created_at_start: ""}${created_at_end? "&created_at_end="+created_at_end: ""}${approved_at_start? "&approved_at_start="+approved_at_start: ""}${approved_at_end? "&approved_at_end="+approved_at_end: ""}${statuses? "&statuses="+statuses: ""}${search? "&search="+search: ""}${created_by? "&created_by="+created_by: ""}${approved_by? "&approved_by="+approved_by: ""}${tags? "&tags="+tags: ""}${order_by? "&order_by="+order_by: ""}`;
//         let response = yield call(HttpService.get,DESIGN_URI,`${projectAPI}${params}`,TOKEN)
//         if (!response.data.error) {
//             let { results } = response.data.data;
//             let bannerArr:Banner[] = [];
//             results.forEach((data:Banner)=>{
//                 bannerArr.push({
//                     id: data.id,
//                     parent: folder_id?folder_id: 0,
//                     title: data.title,
//                     type: data.type,
//                     rendered_file: IMAGE_BASE_URL + data.rendered_file + (detectQueryString(data.rendered_file)? '&q=5': '?q=5')+'&lossless=0',
//                     created_by: data.created_by,
//                     created_at: data.created_at,
//                     can_manage: data.can_manage,
//                     ready_status: data.ready_status,
//                     render_status: data.render_status,
//                     percentage_completion: data.percentage_completion,
//                     file_type: data.file_type
//                 })
//             })
//             response.data.data["data"] = bannerArr;
//             callback && callback(response.data.data,false);
//         } else {
//             callback && callback(response, true);
//         }
//     } catch (error) {
//         callback && callback(error.response, true);
//     }
// }

function* fetchProject(action: FetchProjectAction) {
    let { payload: { id, editor_mode }, callback } = action;
    const TOKEN = yield select(getTokenOrPublic);
    try {
        let query = `${DESIGN_URI}/api/project/${id}?editor_mode=${editor_mode}`;
        let response = yield axios.get(query,
            {
                headers: {
                    token: TOKEN
                }
            }
        );
        if (!response.data.error) {
            let { data } = response.data;
            let bannerArr: any[] = [];

            let project: Project = {
                ...data, ...{
                    rendered_file: IMAGE_BASE_URL + data.rendered_file,
                    banners: data.renderedimages.map((imgObj) => {
                        if (imgObj.render_status === 'NOT_STARTED' && data.rendered_file) {
                            imgObj.rendered_image = data.rendered_file;
                            return imgObj;
                        }
                        return imgObj;
                    }),
                }
            };
            if (callback) {
                callback(project, false);
            } else {
                yield put(setProject(project));
            }
        }
    } catch (error) {
        callback && callback(error.response, true);
        openToast('error', error?.response?.data?.message || "Something went wrong!")
    }
}

function* deleteProjects(action: DeleteProjects) {
    let { payload, callback } = action;
    const TOKEN = yield select(getToken);
    try {
        if (payload.length === 1) {
            let query = `${DESIGN_URI}/api/project/${payload[0]}`
            var response = yield axios.delete(query,
                {
                    headers: {
                        token: TOKEN
                    }
                }
            );
        } else {
            let query = `${DESIGN_URI}/api/project/delete`;
            let body = new FormData();
            payload.forEach((val: number) => {
                body.append("project", val.toString());
            })
            var response = yield axios.post(query, body,
                {
                    headers: {
                        token: TOKEN
                    }
                }
            );
        }
        if (!response.data.error) {
            callback && callback(response.data, false);
            let data = yield select(getFoldersState);
            let banners = data.banners.filter((obj: any) => (action.payload.indexOf(obj.id) === -1));
            yield all([
                put(setProjects(banners)),
                put(setSelectedProject([]))
            ])
            openToast('success', response?.data?.data?.message || "Project deleted!")
        } else {
            callback && callback(response, true);
        }
    } catch (error) {
        callback && callback(error.response, true);
        openToast('error', error?.response?.data?.message || "Something went wrong!")
    }
}


function* moveProjects(action: MoveProjects) {
    let { payload: { selectedProjects, destinationFolderId }, callback } = action;
    let query = `${DESIGN_URI}/api/project/move`;
    const TOKEN = yield select(getToken);
    const product_id = yield select(getProductId);
    try {
        let response = yield axios.post(query, { project_ids: selectedProjects, destination_folder_id: destinationFolderId === 0 ? undefined : destinationFolderId, product_id },
            {
                headers: {
                    token: TOKEN
                }
            }
        );
        //     if(!response.data.error){
        //         let data = yield select(getFoldersState);
        //         let banners = data.banners.filter((obj:any)=>(action.payload.selectedProjects.indexOf(obj.id) === -1))
        //         yield all([
        //             put(setProjects(banners)),
        //             put(setSelectedProject([]))
        //         ])
        //         openToast('success',response?.data?.data?.message || "Project moved!")
        //     }
        // }
        // catch(error){
        //     openToast('error',error?.response?.data?.message || "Something went wrong!")
        // }
        if (!response.data.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true)
        }
    } catch (error) {
        callback && callback(error.response, true);
        yield put(setSelectedProject([]))
        openToast('error', error?.response?.data?.message || "Something went wrong!")
    }
}


function* fetchFiles(action: FetchFilesAction) {
    let { payload: { page, page_size, type, folder }, callback } = action;
    const TOKEN = yield select(getToken);
    const product_id = yield select(getProductId);
    try {
        let query = `${DESIGN_URI}/api/file?${page ? "page=" + page : ""}${type ? "&type=" + type : ""}&page_size=${page_size}&${folder ? "folder=" + folder : ""}&product_id=${product_id}`;
        let response = yield axios.get(query,
            {
                headers: {
                    token: TOKEN
                }
            }
        );
        if (!response.data.error) {
            let banners = (yield select(getFoldersState)).banners;
            let { results } = response.data.data;
            let filesArr: any = [];
            results.forEach((data: any) => {
                let ext = data.file_url.split('.').pop();
                filesArr.push({
                    id: data.id,
                    parent: folder ? folder : 0,
                    title: data.title,
                    type: data.resource_type,
                    can_manage: data.can_manage,
                    rendered_file: S3_BASE_URL + data.file_url + (ext !== 'gif' ? (detectQueryString(data.file_url) ? '&q=5' : '?q=5') + '&lossless=0' : '')
                })
            });
            if (callback) {
                callback({ ...response.data.data, results: filesArr }, false);
            } else {
                yield all([
                    put(setFoldersPageData({
                        count: response.data.data.count,
                        current_page: response.data.data.current_page,
                        page_size: response.data.data.page_size,
                        pages: response.data.data.pages
                    })),
                    put(setProjects(response.data.data.current_page > 1 ? [...banners, ...filesArr] : filesArr))
                ])
            }
        } else {
            callback && callback(response, true)
        }
    } catch (error) {
        callback && callback(error.response, true);
        openToast('error', error?.response?.data?.message || "Something went wrong!")
    }
}


function* moveFiles(action: MoveFiles) {
    let { payload: { selectedFiles, destinationFolderId }, callback } = action;
    const TOKEN = yield select(getToken);
    const product_id = yield select(getProductId);
    try {
        let query = `${DESIGN_URI}/api/file/move`;
        let response = yield axios.post(query, { file_ids: selectedFiles, destination_folder_id: destinationFolderId === 0 ? undefined : destinationFolderId, product_id },
            {
                headers: {
                    token: TOKEN
                }
            }
        );
        if (!response.data.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true)
        }
    } catch (error) {
        callback && callback(error.response, true);
        yield put(setSelectedProject([]))
        openToast('error', error?.response?.data?.message || "Something went wrong!")
    }
}

function* uploadLibraryFile(action: UploadLibraryFile) {
    let { payload: { file, folder, type }, callback } = action;
    const TOKEN = yield select(getToken);
    const product_id = yield select(getProductId);
    try {
        let query = `${DESIGN_URI}/api/file`;
        let fileform: any = new FormData();
        file.forEach((f: any) => {
            fileform.append("file", f);
            fileform.append("title", f.name);
        })
        fileform.append("type", type);
        fileform.append("product_id", product_id);
        if (folder) fileform.append("folder", folder);
        let response = yield axios.post(query, fileform,
            {
                headers: {
                    token: TOKEN
                }
            }
        );
        if (!response.data.error) {
            let data = yield select(getFoldersState);
            let banner: any = {
                id: response.data.data.id,
                parent: folder ? folder : 0,
                title: response.data.data.title,
                type: response.data.data.resource_type,
                rendered_file: IMAGE_BASE_URL + response.data.data.file_url + (detectQueryString(response.data.data.file_url) ? '&q=5' : '?q=5') + '&lossless=0'
            };
            if (callback) {
                callback(response.data, false)
            } else {
                yield put(setProjects([banner, ...data.banners]));
            }
            openToast('success', response?.data?.data?.message || `File${Array.isArray(response?.data?.data) ? 's' : ''} uploaded!`);
        } else {
            callback && callback(response, true)
        }
    } catch (error) {
        openToast('error', error?.response?.data?.message || "Something went wrong!")
    }
}

function* fetchLibraryFile(action: FetchLibraryFile) {
    let { payload: { project_id }, callback } = action;
    const TOKEN = yield select(getToken);
    try {
        let query = `${DESIGN_URI}/api/file/${project_id}`;
        let response = yield axios.get(query,
            {
                headers: {
                    token: TOKEN
                }
            }
        );
        if (!response.data.error) {
            callback && callback(response.data, false);
            openToast('success', response?.data?.data?.message || "File downloading...")
        } else {
            callback && callback(response, true)
        }
    } catch (error) {
        openToast('error', error?.response?.data?.message || "Something went wrong!")
    }
}

function* deleteFiles(action: DeleteFiles) {
    let { payload, callback } = action;
    const TOKEN = yield select(getToken);
    try {
        if (payload.length === 1) {
            let query = `${DESIGN_URI}/api/file/${payload[0]}`
            var response = yield axios.delete(query,
                {
                    headers: {
                        token: TOKEN
                    }
                }
            );
        } else {
            let query = `${DESIGN_URI}/api/file/delete`;
            let body = new FormData();
            payload.forEach((val: number) => {
                body.append("file", val.toString());
            })
            var response = yield axios.post(query, body,
                {
                    headers: {
                        token: TOKEN
                    }
                }
            );
        }

        if (!response.data.error) {
            if (callback) {
                callback(response.data, false)
            } else {
                let data = yield select(getFoldersState);
                let banners = data.banners.filter((obj: any) => (action.payload.indexOf(obj.id) === -1))
                yield all([
                    put(setProjects(banners)),
                    put(setSelectedProject([]))
                ])
            }
            openToast('success', response?.data?.data?.message || "File deleted!")
        } else {
            callback && callback(response, true)
        }
    } catch (error) {
        yield put(setSelectedProject([]))
        openToast('error', error?.response?.data?.message || "Something went wrong!")
    }
}

function* addProjectToTemplate(action: AddProjectToTemplate) {
    let { payload: { id, title }, callback } = action;
    const TOKEN = yield select(getToken);
    try {
        let query = `${DESIGN_URI}/api/project/${id}/mytemplate/`
        var response = yield axios.post(query, { title },
            {
                headers: {
                    token: TOKEN
                }
            }
        );
        if (!response.data.error) {
            callback && callback(response.data, false);
            openToast('success', response?.data?.data?.message || "Project added as template!")
        } else {
            callback && callback(response.data, true);
        }
    } catch (error) {
        callback && callback(error?.response, true);
        openToast('error', error?.response?.data?.message || "Something went wrong!")
    }
}

function* fetchHyperPersonlizeProjects(action: FetchHyperProjects) {
    let { payload: { hpproject_ids, folder, page, page_size, created_at_start, created_at_end, approved_at_end, approved_at_start, statuses, search, created_by, approved_by, tags, order_by }, callback } = action;
    const TOKEN: any = yield select(getTokenOrPublic);
    const product_id: any = yield select(getProductId);
    try {
        let params = `?page=${page}&page_size=${page_size}${folder ? '&folder=' + folder : ''}${hpproject_ids ? '&hpproject_ids=' + hpproject_ids : ''}&product_id=${product_id}${created_at_start ? "&created_at_start=" + created_at_start : ""}${created_at_end ? "&created_at_end=" + created_at_end : ""}${approved_at_start ? "&approved_at_start=" + approved_at_start : ""}${approved_at_end ? "&approved_at_end=" + approved_at_end : ""}${statuses ? "&statuses=" + statuses : ""}${search ? "&search=" + search : ""}${created_by ? "&created_by=" + created_by : ""}${approved_by ? "&approved_by=" + approved_by : ""}${tags ? "&tags=" + tags : ""}${order_by ? "&order_by=" + order_by : ""}`;
        let response = yield call(HttpService.get, DESIGN_URI, `${hyperProjectsAPI}${params}`, TOKEN)
        if (!response.data.error) {
            let { results } = response.data.data;
            let bannerArr: Banner[] = [];
            results.forEach((data: Banner) => {
                bannerArr.push({
                    ...data, ...{
                        parent: folder ? folder : 0,
                        title: data.title,
                        type: data.type,
                        rendered_file: `${IMAGE_BASE_URL + data.rendered_file + (detectQueryString(data.rendered_file) ? '&q=40' : '?q=40')}&auto=format`,
                        created_by: data.created_by,
                        created_at: data.created_at,
                        can_manage: data.can_manage || true
                    }
                })
            })
            yield put(setFoldersPageData({
                count: response.data.data.count,
                current_page: response.data.data.current_page,
                page_size: response.data.data.page_size,
                pages: response.data.data.pages
            }))
            callback && callback(bannerArr, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error) {
        callback && callback(error.response, true);
    }
}

function* fetchAllFiles(action: FetchAllFiles) {
    let { payload: { page, page_size, folder, type, resource_type }, callback } = action;
    const TOKEN: any = yield select(getToken);
    const product_id: any = yield select(getProductId);
    try {
        let params = `?page=${page}&page_size=${page_size}${folder ? '&folder=' + folder : ''}${type ? '&type=' + type : ''}${resource_type ? '&resource_type=' + resource_type : ''}&product_id=${product_id}`;
        let response = yield call(HttpService.get, DESIGN_URI, `${uploadFileAPI}${params}`, TOKEN)

        if (!response.data.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error) {
        callback && callback(error.response, true);
    }
}
function* duplicateProject(action: DuplicateProject) {
    let { payload: { id }, callback } = action;
    const TOKEN: any = yield select(getToken);
    try {
        let params = `/add-variant/duplicate/${id}/`;
        let response = yield call(HttpService.post, DESIGN_URI, `${projectAPI}${params}`, TOKEN, {})

        if (!response.data.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error) {
        callback && callback(error.response, true);
    }
}

function* fetchTags(action: FetchTags) {
    let { payload: { type, page, page_size, folder }, callback } = action;
    const TOKEN: any = yield select(getToken);
    const product_id: any = yield select(getProductId);
    try {
        let params = `?page=${page}&product_id=${product_id}${page_size ? '&page_size=' + page_size : ''}${folder ? '&folder=' + folder : ''}${type ? '&type=' + type : ''}`;
        let response = yield call(HttpService.get, DESIGN_URI, `${tagsAPI}${params}`, TOKEN)

        if (!response.data.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error) {
        callback && callback(error.response, true);
    }
}

function* generateShareableLink(action: GenerateShareableLink) {
    let { payload: { project_id, vleId_flag, password_flag, expiration_date, projectType, shareable_type }, callback } = action;
    const TOKEN: any = yield select(getToken);
    const product_id: any = yield select(getProductId);
    try {
        let params = `/${project_id}/generate-public-link/?password_flag=${password_flag}&vleId_flag=${vleId_flag}${expiration_date ? '&expiration_date=' + expiration_date : ''}${shareable_type ? '&shareable_type=' + shareable_type : ''}`;
        let response = yield call(HttpService.post, DESIGN_URI, `/api/${projectType}${params}`, TOKEN, {})
        if (!response.data.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error) {
        callback && callback(error.response, true);
    }
}

function* addOrDeleteTag(action: AddOrDeleteTag) {
    const { payload: { entity_id, entity_type, name, type }, callback } = action;
    const TOKEN: any = yield select(getToken);
    const product_id: any = yield select(getProductId);
    try {
        let params = `/new`;
        let response = yield call(type === 'add' ? HttpService.post : HttpService.delete, DESIGN_URI, `${tagsAPI}${params}`, TOKEN, { product_id, entity_id, entity_type, name })

        if (!response.data.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error) {
        callback && callback(error.response, true);
    }
}

function* deleteShareableLink(action: DeleteShareableLink) {
    let { payload: { projectId, projectType, shareable_type }, callback } = action;
    const TOKEN: any = yield select(getToken);
    const product_id: any = yield select(getProductId);
    try {
        let params = `/${projectId}/generate-public-link/?shareable_type=${shareable_type}`;
        let response = yield call(HttpService.delete, DESIGN_URI, `/api/${projectType}${params}`, TOKEN, {})

        if (!response.data.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error) {
        callback && callback(error.response, true);
    }
}

function* fetchProjectFromShareableLink(action: FetchProjectFromShareableLink) {
    let { payload: { shareable_link, password, projectType }, callback } = action;

    try {
        let params = `/${shareable_link}${password ? '?password=' + password : ''}`;
        let response = yield call(HttpService.get, DESIGN_URI, `/api/${projectType}${params}`, undefined)

        if (!response.data.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error) {
        callback && callback(error.response, true);
    }
}

function* getShareableLink(action: GetShareableLink) {
    let { payload: { project_id, project_type, shareable_type }, callback } = action;
    const TOKEN: any = yield select(getToken);

    try {
        let params = `/${project_id}/generate-public-link/?shareable_type=${shareable_type}`;
        let response = yield call(HttpService.get, DESIGN_URI, `/api/${project_type}${params}`, TOKEN)

        if (!response.data.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error) {
        callback && callback(error.response, true);
    }
}

function* getTokenFromShareableLink(action: GetTokenFromShareableLink) {
    let { payload: { hash_code, project_type, password, vle_code }, callback } = action;

    try {
        let params = `/public-link-access-token?${project_type === 'hp-project' ? 'hp' : ''}project_hash=${hash_code}${password ? '&password=' + password : ""}${vle_code ? '&vleId=' + vle_code : ""}`;
        let response = yield call(HttpService.get, DESIGN_URI, `/api/${project_type}${params}`, undefined)

        if (!response.data.error) {
            if (response?.data?.data?.vle_ID != null && response?.data?.data?.vle_ID != "") {
                let vle_ID = response?.data?.data?.vle_ID;
                linkOpened(vle_ID);

                sessionStorage.setItem('vleId', JSON.stringify(response?.data?.data?.vle_ID));
            }
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error) {
        callback && callback(error.response, true);
    }
}

function* searchTags(action: SearchTags) {
    const { payload: { search, entityType }, callback } = action;
    const TOKEN: any = yield select(getToken);
    const product_id: any = yield select(getProductId);
    try {
        let params = `/${search}?product_id=${product_id}${entityType ? '&type=' + entityType : ''}`;
        let response = yield call(HttpService.get, DESIGN_URI, `${tagsRecommendationAPI}${params}`, TOKEN)

        if (!response.data.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error) {
        callback && callback(error.response, true);
    }
}

function* fileToProject(action: FileToProject) {
    const { payload: { file, type, folder, cancelToken }, callback, config } = action;
    const TOKEN: any = yield select(getToken);
    const product_id: any = yield select(getProductId);
    try {
        let params = `/api/file-to-project`;
        let fileform: any = new FormData();
        fileform.append("type", type);
        fileform.append("product_id", product_id);
        fileform.append("file", file);
        if (folder) {
            fileform.append("folder", folder)
        }
        let response = yield call(HttpService.post, DESIGN_URI, `${params}`, TOKEN, fileform, config, cancelToken.token)
        if (!response.data.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error) {
        callback && callback(error.response, true);
    }
}

function* updateFileToProject(action: UpdateFileToProject) {
    const { payload: { project_id, file }, callback } = action;
    const TOKEN: any = yield select(getToken);
    try {
        let params = `/api/file-to-project`;
        let fileform: any = new FormData();
        fileform.append("project_id", project_id);
        fileform.append("file", file);
        let response = yield call(HttpService.put, DESIGN_URI, `${params}`, TOKEN, fileform);
        if (!response.data.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error) {
        callback && callback(error.response, true);
    }
}

function* updateVariant(action: UpdateVariant) {
    const { payload: { project_id, file, project_size }, callback } = action;
    const TOKEN: any = yield select(getToken);
    try {
        let params = `/api/add-variant/update/${project_id}`;
        let fileform: any = new FormData();
        fileform.append("file", file);
        fileform.append("project_size", project_size);
        let response = yield call(HttpService.put, DESIGN_URI, `${params}`, TOKEN, fileform);
        if (!response.data.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error) {
        callback && callback(error.response, true);
    }
}

function* createVariant(action: CreateVariant) {
    const { payload: { project_id, file }, callback } = action;
    const TOKEN: any = yield select(getToken);
    try {
        let params = `/api/add-variant/create/${project_id}`;
        let fileform: any = new FormData();
        fileform.append("file", file);
        let response = yield call(HttpService.post, DESIGN_URI, `${params}`, TOKEN, fileform);
        if (!response.data.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error) {
        callback && callback(error.response, true);
    }
}

function* deleteVariant(action: DeleteVariant) {
    const { payload: { project_id, project_size }, callback } = action;
    const TOKEN = yield select(getToken);
    try {
        let params = `/api/add-variant/delete/${project_id}`;
        let fileform: any = new FormData();
        fileform.append("project_size", project_size);
        let response = yield call(HttpService.delete, DESIGN_URI, `${params}`, TOKEN, fileform);
        if (!response.data.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error) {
        callback && callback(error.response, true);
    }
}

// Abort fileToProject API 
function* cancelFileToProject() {
    fileToProjectToken.cancel();
}

// Reset API for new request
// Created different function to reset token to support aborting all API regardless of throttling
function* resetFileToProject() {
    fileToProjectToken = axios.CancelToken.source();
}
function* fileUploader(action: FileUploader) {
    const { payload: { file_name, file_size, file, config, cancelToken }, callback } = action;
    const TOKEN: any = yield select(getToken);
    let url = process.env.REACT_APP_PROJECT_UPLOAD_URL;
    var megaBytes = 1024 * 1024;
    const size = (file_size / megaBytes).toFixed(2)
    try {
        let params = `${file_name ? "file_name=" + encodeURIComponent(file_name) : ""}${file_size ? "&file_size=" + size : ""}${TOKEN ? "&token=" + TOKEN : ""}`;
        let response = yield call(HttpService.get, url, `?${params}`, TOKEN, cancelToken.token)
        if (response?.data?.uploadId) {
            const FILE_CHUNK_SIZE = 1000000000// 100MB
            // const NUM_CHUNKS = Math.floor(file_size / FILE_CHUNK_SIZE) + 1
            const NUM_CHUNKS = Math.ceil(file_size / FILE_CHUNK_SIZE);

            let promisesArray = []
            const uploadId = response.data.uploadId

            for (let index = 1; index < NUM_CHUNKS + 1; index++) {
                // (1) Generate presigned URL for each part
                // start = (index - 1) * FILE_CHUNK_SIZE
                // end = (index) * FILE_CHUNK_SIZE
                // blob = (index < NUM_CHUNKS) ? file.slice(start, end) : file.slice(start)
                const start = (index - 1) * FILE_CHUNK_SIZE
                const end = Math.min(start + FILE_CHUNK_SIZE, file_size);
                const blob = file.slice(start, end);

                let params = `${file_name ? "file_name=" + encodeURIComponent(file_name) : ""}${"&part_number=" + index}${"&upload_id=" + uploadId}${TOKEN ? "&token=" + TOKEN : ""}`;
                let getUploadUrlResp = yield call(HttpService.get, url, `?${params}`, TOKEN, cancelToken.token)
                let { presignedUrl } = getUploadUrlResp.data

                // (2) Puts each file part into the storage server
                // let uploadResp = axios.put(
                //   presignedUrl,
                //   blob,
                //   { headers: { 'Content-Type': this.state.selectedFile.type } }
                // )

                let uploadResp = yield axios.put(presignedUrl, blob,
                    {
                        ...config,
                        headers: { 'Content-Type': file.type },
                        cancelToken: cancelToken.token
                    }
                );
                promisesArray.push(uploadResp)
            }
            let resolvedArray = yield Promise.all(promisesArray)
            let uploadPartsArray = []
            resolvedArray.forEach((resolvedPromise, index) => {
                uploadPartsArray.push({
                    ETag: resolvedPromise.headers.etag,
                    PartNumber: index + 1
                })
            })
            let params = `${file_name ? "file_name=" + encodeURIComponent(file_name) : ""}${"&parts=" + encodeURIComponent(JSON.stringify(uploadPartsArray))}${"&complete=" + true}${"&upload_id=" + uploadId}${TOKEN ? "&token=" + TOKEN : ""}`;
            let completeUploadResp = yield call(HttpService.get, url, `?${params}`, TOKEN, cancelToken.token)


            if (!completeUploadResp?.data?.error) {
                callback && callback({ ...response.data, uploadUrl: completeUploadResp.data.location, ...completeUploadResp?.data }, false);
                return;
            } else {
                callback && callback(completeUploadResp, true);
                return;
            }
        }
        if (!response.data.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error) {
        callback && callback(error.response, true);
    }
}

function* uploadToS3(action: UploadToS3) {
    const { payload: { file, file_url, cancelToken }, callback, config } = action;
    try {
        let response = yield axios.put(file_url, file,
            {
                ...config,
                cancelToken: cancelToken.token
            }
        );
        if (!response.data.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error) {
        callback && callback(error.response, true);
    }
}

function* projectUpload(action: ProjectUpload) {
    const { payload: { title, type, file_type, template, folder, rendered_file, sizes, projectJson, psdUrl, cancelToken }, callback } = action;
    const TOKEN: any = yield select(getToken);
    const product_id: any = yield select(getProductId);
    try {
        let params = `/api/project-by-upload/new`;
        let fileform: any = new FormData();
        fileform.append("title", title);
        fileform.append("type", type);
        fileform.append("file_type", file_type);
        fileform.append("rendered_file", rendered_file);
        fileform.append("product_id", product_id);
        if (folder)
            fileform.append("folder", folder)
        if (template) {
            fileform.append("template", template);
        } if (sizes) {
            fileform.append("sizes", JSON.stringify(sizes));
        } if (projectJson) {
            fileform.append("project_json", JSON.stringify(projectJson));
        } if (psdUrl) {
            fileform.append("psdUrl", psdUrl);
        }
        let response = yield call(HttpService.post, DESIGN_URI, `${params}`, TOKEN, fileform, {}, cancelToken.token)
        if (!response.data.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error) {
        callback && callback(error.response, true);
    }
}

function* createFolderStruture(action: CreateFolderSutructure) {
    const { payload: { structure, projectType, parent_id }, callback } = action;
    const TOKEN: any = yield select(getToken);
    const product_id: any = yield select(getProductId);

    try {
        let params = `/create-directory-structure`;
        let response = yield call(HttpService.post, DESIGN_URI, `${getSubFoldersAPI}${params}`, TOKEN, {
            structure, type: projectType, parent_id, product_id
        })

        if (!response.data.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error) {
        callback && callback(error.response, true);
    }
}

function* getQuickViewBucket(action: GetQuickViewBucket) {
    let { payload: { role }, callback } = action;
    const TOKEN = yield select(getToken);
    try {
        let params = `?role=${role}`;
        let response = yield call(HttpService.get, `${AUDIT_URI}/buckets`, `${params}`, TOKEN);

        if (!response.data.message) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response.data.message, true);
        }
    } catch (error) {
        callback && callback(error.response, true);
        openToast('error', error?.response?.data?.message || "Something Went Wrong!")
    }
}

function* updateProjectDescripiton(action: UpdateProjectDescription) {
    const { payload: { project_id, body }, callback } = action;
    const TOKEN: any = yield select(getToken);
    const product_id: any = yield select(getProductId);

    try {
        let params = `/${project_id}`;
        let response = yield call(HttpService.put, DESIGN_URI, `${projectDescription}${params}`, TOKEN, body)

        if (!response.data.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error) {
        callback && callback(error.response, true);
    }
}

function* searchMeta(action: SearchTags) {
    const { payload: { search, entityType }, callback } = action;
    const TOKEN: any = yield select(getToken);
    const product_id: any = yield select(getProductId);
    try {
        let params = `?type=${entityType}&product_id=${product_id}&q=${search}`;
        let response = yield call(HttpService.get, DESIGN_URI, `${designMetaRecommendataionAPI}${params}`, TOKEN)

        if (!response.data.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error) {
        callback && callback(error.response, true);
    }
}

function* addMeta(action: AddMeta) {
    const { payload: { type, project_size_id, data }, callback } = action;
    const TOKEN: any = yield select(getToken);
    const product_id: any = yield select(getProductId);
    try {
        let response = yield call(HttpService.post, DESIGN_URI, `${designMetaAPI}`, TOKEN, { type, project_size_id, data, product_id })

        if (!response.data.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error) {
        callback && callback(error.response, true);
    }
}

function* deleteMeta(action: DeleteMeta) {
    const { payload: { id, type, project_size_id }, callback } = action;
    const TOKEN: any = yield select(getToken);
    const product_id: any = yield select(getProductId);
    try {
        let response = yield call(HttpService.delete, DESIGN_URI, `${designMetaAPI}`, TOKEN, { id, type, product_id, project_size_id })

        if (!response.data.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error) {
        callback && callback(error.response, true);
    }
}

function* fetchDesignMeta(action: FetchDesignMeta) {
    let { payload: { project_size_id }, callback } = action;
    const TOKEN = yield select(getTokenOrPublic);
    try {
        let url = `${DESIGN_URI}/api/meta?project_size_id=${project_size_id}`;
        let response = yield axios.get(url,
            {
                headers: {
                    token: TOKEN
                }
            }
        );
        if (!response.data.error) {
            callback && callback(response.data, false);
        } else {
            callback && callback(response, true);
        }
    } catch (error) {
        callback && callback(error.response, true);
        openToast('error', error?.response?.data?.message || "Something went wrong!")
    }
}

export function* foldersWatcher() {
    yield takeLatest(FETCH_FOLDERS, getFolders)
    yield takeLatest(ADD_NEW_FOLDER, addNewFolder)
    yield takeLatest(MOVE_FOLDER, moveFolder)
    yield takeLatest(DELETE_FOLDER, deleteFolder)
    yield takeLatest(UPDATE_FOLDER, updateFolder)
    yield takeLatest(FETCH_PROJECTS, fetchProjects)
    yield takeLatest(FETCH_PROJECT, fetchProject)
    yield takeLatest(DELETE_PROJECTS, deleteProjects)
    yield takeLatest(MOVE_PROJECTS, moveProjects)
    yield takeLatest(FETCH_FILES, fetchFiles)
    yield takeLatest(UPLOAD_LIBRARY_FILE, uploadLibraryFile)
    yield takeLatest(FETCH_LIBRARY_FILE, fetchLibraryFile)
    yield takeLatest(MOVE_FILES, moveFiles)
    yield takeLatest(DELETE_FILES, deleteFiles)
    yield takeLatest(ADD_PROJECT_TO_TEMPLATE, addProjectToTemplate)
    yield takeLatest(FETCH_HYPER_PROJECTS, fetchHyperPersonlizeProjects)
    yield takeLatest(FETCH_ALL_FILES, fetchAllFiles)
    yield takeLatest(DUPLICATE_PROJECT, duplicateProject)
    yield takeLatest(FETCH_TAGS, fetchTags)
    yield takeLatest(GENERATE_SHAREABLE_LINK, generateShareableLink)
    yield takeLatest(DELETE_SHAREABLE_LINK, deleteShareableLink)
    yield takeLatest(FETCH_PROJECT_FROM_SHAREABLE_LINK, fetchProjectFromShareableLink)
    yield takeLatest(GET_SHAREABLE_LINK, getShareableLink)
    yield takeLatest(ADD_OR_DELETE_TAG, addOrDeleteTag)
    yield takeLatest(SEARCH_TAGS, searchTags)
    yield takeLatest(GET_TOKEN_FROM_SHAREABLE_LINK, getTokenFromShareableLink)
    yield takeEvery(FILE_TO_PROJECT, fileToProject)
    yield takeEvery(UPDATE_FILE_TO_PROJECT, updateFileToProject)
    yield takeEvery(UPDATE_VARIANT, updateVariant)
    yield takeEvery(CREATE_VARIANT, createVariant)
    yield takeEvery(DELETE_VARIANT, deleteVariant)
    yield takeEvery(FILE_UPLOADER, fileUploader)
    yield takeEvery(UPLOAD_TO_S3, uploadToS3)
    yield takeEvery(PROJECT_UPLOAD, projectUpload)
    yield takeEvery(CREATE_FOLDER_STRUCTURE, createFolderStruture)
    yield takeEvery(GET_QUICK_VIEW_BUCKET, getQuickViewBucket)
    yield takeLatest(UPDATE_PROJECT_DESCRIPTION, updateProjectDescripiton)
    yield takeLatest(SEARCH_META, searchMeta)
    yield takeLatest(ADD_META, addMeta)
    yield takeLatest(DELETE_META, deleteMeta)
    yield takeLatest(FETCH_DESIGN_META, fetchDesignMeta)
}