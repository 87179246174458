//  Component used
//  1. Search
//  2. Template
//  3. Design
//  4. hpProject

// Imported project card and submit for approval not woking? You'll need to import ApprovalAert component in parent of ProjectCard
// As we want to hit fetchapproval only once we are importing approval alert in parent of projectCard
import React, { useState, useEffect, useCallback } from "react";
import "./index.scss";
import { FolderState, AuthState, LibraryState } from "../../../store/types";
import {
  setSelectedProject,
  setProjects,
  deleteProjects,
  deleteHyperProjects,
  deleteFiles,
  getHyperProject,
  renderImages,
  generateCombinations,
  fetchProject,
  setSelectedMyTemplates,
  createProjectFromTemplate,
  deleteMyTemplates,
} from "../../../store/actions";
import MultipleSet from "../../../assets/svg/multipleSet.svg";
// import { ReactComponent as projectLoader} from '../../../assets/svg/projectLoader.svg';
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  permissionDeniedClick,
  getStatusColor,
  detectQueryString,
  getPreview,
  getFileTypeFromExtension,
  getIsTypeEditable,
} from "../../../utilities/common-function";
import { openToast } from "../../../Toasts";
import {
  WEBSOCKET_ENDPOINT,
  IMAGE_BASE_URL,
  AUDIT_URI,
} from "../../../utilities/paths";
import Img from "../../ImageLoader/ImageLoader";
import useWebSocket from "../../shared/hooks/useWebSocket";
import projectLoader from "../../../assets/animation/projectLoader.json";
import { DropDownOption, EditAlert, ApprovalAlert } from "../../shared";
import {
  Button,
  Checkbox,
  Modal as AntModal,
  Progress,
  Tooltip,
} from "pyxis-ui-kit";
import { EditOutlined, ReloadOutlined } from "@ant-design/icons";
import AudioFileSVG from "../../../assets/svg/audio-file.svg";
import VideoFileSVG from "../../../assets/svg/video-file.svg";
import ZipFileSVG from "../../../assets/svg/zip-file.svg";
import PPTFileSVG from "../../../assets/svg/ppt-file.svg";
import ImageFileSVG from "../../../assets/svg/image-file.svg";
import InternetFileSVG from "../../../assets/svg/internet-file.svg";
import DocFileSVG from "../../../assets/svg/doc-file.svg";

import Lottie from "react-lottie";
import moment from "moment";
import { isEmpty } from "lodash";
import { store } from "../../../store/store";
import map from "lodash/map";
import debounce from "lodash/debounce";
import axios from "axios";

interface CardInterface {
  key: number;
  data: any;
  type: string;
  projectIndex: number;
  showOverlay?: boolean;
  createHyperProject?: Function;
  setOpenDownloadBox?: Function;
  scrollToTop?: Function;
  allowSelection?: boolean;
  componentCaller?: string;
  setCurrentProject?: Function;
  drawerVisible?: string;
  setDrawerVisible?: Function;
  setPublicLinkDrawerProps?: Function;
  setViewDetailsDrawerProps?: Function;
  // hasFailedProjects?:boolean,
  // isMultipleDownloadDisable?:boolean
  isEditEnabled?: boolean;
}

const ProjectCard: React.FunctionComponent<CardInterface> = ({
  key,
  data,
  type: projectType,
  showOverlay = true,
  setPublicLinkDrawerProps,
  setViewDetailsDrawerProps,
  scrollToTop,
  projectIndex,
  allowSelection = true,
  componentCaller,
  setCurrentProject,
  createHyperProject,
  drawerVisible,
  setDrawerVisible,
  isEditEnabled,
}) => {
  const [rendering, setRendering] = useState(false);
  const [isViewFile, setIsViewFile] = useState(false);
  const [isDeletOverlay, setIsDeleteOverlay] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isOpenMoreVisible, setIsOpenMoreVisible] = useState(false);
  const [dropDownStep, setDropDownStep] = useState(0);
  const [project, setProject] = useState<any>(data);
  const state = useSelector((state: { folders: FolderState }) => state.folders);
  const authState = useSelector(
    (state: { authentication: AuthState }) => state.authentication
  );
  const TemplateState = useSelector(
    (state: { library: LibraryState }) => state.library
  );
  const { token, userData } = authState;
  const { selectedTemplates } = TemplateState;
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    rendered_file,
    title,
    id,
    type,
    status,
    sizes,
    render_status,
    ready_status,
    created_at,
    rendered_count,
    can_manage,
    total_count,
    percentage_completion,
  } = data;
  const { selectedProjects, banners } = state;
  const isSelected =
    projectType !== "template"
      ? selectedProjects.indexOf(id) > -1
      : selectedTemplates.indexOf(project.id) > -1;
  const [hpclient, setHPClientActions] = useWebSocket(
    `${WEBSOCKET_ENDPOINT}/hpproject/${data.id}/ready-update?token=${token}`,
    data.ready_status === "NOT_STARTED" || data.ready_status === "INPROGRESS"
  );
  const [client, setClientActions] = useWebSocket(
    `${WEBSOCKET_ENDPOINT}/entity/${data.id}/update?token=${token}&type=${projectType}`,
    data.render_status === "INPROGRESS"
  );
  const isPublicLink = history.location.pathname.startsWith("/cocreate/public");
  let timer = 0;
  let prevent = false;

  useEffect(() => {
    setProject(data);
  }, [data, data?.created_by?.firstName]);

  const designClickEvent = () => {
    const eventData = {};
    eventData["event_meta"] = {};
    eventData["event_meta"]["entity_id"] = id.toString();
    eventData["event_meta"]["entity_type"] = projectType;
    eventData["event_meta"]["user_id"] = userData.userId;
    eventData["event_name"] = "design_clicked";
    axios
      .post(AUDIT_URI + "/event", eventData)
      .then((response) => {
        if (response.status === 200) {
          return true;
        }
      })
      .catch((e) => {
        console.log("User Click event has an error");
      });
  };

  useEffect(() => {
    if (client) {
      setClientActions((val: any) => ({
        ...val,
        onError: (err: any) => {
          console.error("error", "Not able to connect!");
        },
        onRecieve: (data: any) => {
          data.rendered_file = IMAGE_BASE_URL + data.rendered_file;
          store.getState().folders.banners[projectIndex] = {
            ...data,
            can_manage:
              store.getState().folders.banners[projectIndex]["can_manage"] ||
              true,
          };
          dispatch(setProjects(store.getState().folders.banners));
          // data.rendered_file = IMAGE_BASE_URL + data.rendered_file;
          // banners[projectIndex] = { ...data, can_manage: banners[projectIndex]["can_manage"] || true };
          // dispatch(setProjects(banners))
        },
      }));
    }
    if (hpclient && projectType === "hpproject") {
      setHPClientActions((val: any) => ({
        ...val,
        onError: (err: any) => {
          console.error("error", "Not able to connect!");
        },
        onRecieve: (data: any) => {
          data.rendered_file =
            IMAGE_BASE_URL +
            data.rendered_file +
            (detectQueryString(data.rendered_file) ? "&q=40" : "?q=40");
          banners[projectIndex] = data;
          banners[projectIndex]["can_manage"] = true;
          dispatch(setProjects(banners));
        },
      }));
    }
  }, []);

  useEffect(() => {
    let timer: any;
    if (
      (data.render_status === "COMPLETED" || data.render_status === "FAILED") &&
      client
    ) {
      client.close();
    }
    if (
      (data.ready_status === "COMPLETED" || data.ready_status === "FAILED") &&
      hpclient
    ) {
      hpclient.close();
    }
    if (projectType === "project" && data.render_status === "INPROGRESS") {
      timer = setTimeout(() => {
        dispatch(
          fetchProject(
            { id: data.id, editor_mode: false },
            (data: any, err: boolean) => {
              if (data.render_status !== "INPROGRESS") {
                store.getState().folders.banners[projectIndex] = {
                  ...store.getState().folders.banners[projectIndex],
                  ...data,
                };
                if (client) {
                  client.close();
                }
                dispatch(setProjects(store.getState().folders.banners));
                // banners[projectIndex] = { ...banners[projectIndex], ...data }
                // if (client) { client.close() }
                // dispatch(setProjects(banners))
              }
            }
          )
        );
      }, 5000);
    }
    if (
      projectType === "hpproject" &&
      ((data.ready_status === "NOT_STARTED" && !data.ready_failure_reason) ||
        data.render_status === "INPROGRESS")
    ) {
      timer = setTimeout(() => {
        dispatch(
          getHyperProject(
            { projectId: data.id, with_default_json: false },
            (res: any, err: boolean) => {
              const { data } = res;
              if (
                data.ready_status !== "INPROGRESS" ||
                data.render_status !== "INPROGRESS"
              ) {
                data.rendered_file = IMAGE_BASE_URL + data.rendered_file;
                banners[projectIndex] = { ...banners[projectIndex], ...data };
                banners[projectIndex]["can_manage"] = true;
                if (hpclient && data.ready_status !== "INPROGRESS") {
                  hpclient.close();
                }
                if (client && data.render_status !== "INPROGRESS") {
                  client.close();
                }
                dispatch(setProjects(banners));
              }
            }
          )
        );
      }, 5000);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [data.render_status]);

  const afterDelete = () => {
    dispatch(setProjects(banners.filter((obj: any) => obj.id !== id)));
    setLoader(false);
    setIsDeleteOverlay(false);
  };

  const handleDelete = (id: number) => {
    setLoader(true);
    if (projectType === "file") {
      dispatch(
        deleteFiles([id], (res: any, err: boolean) => {
          if (!err) {
            afterDelete();
          }
        })
      );
    } else if (projectType === "project") {
      dispatch(
        deleteProjects([id], (res: any, err: boolean) => {
          if (!err) {
            afterDelete();
          }
        })
      );
    } else if (projectType === "hpproject") {
      dispatch(
        deleteHyperProjects([id], (res: any, err: boolean) => {
          if (!err) {
            afterDelete();
          }
        })
      );
    } else if (projectType === "template") {
      dispatch(
        deleteMyTemplates([id], (res: any, err: boolean) => {
          setLoader(false);
          setIsDeleteOverlay(false);
        })
      );
    }
  };

  const handleOpenInEditor = (e: any) => {
    if (!can_manage || !isEditEnabled) {
      e.stopPropagation();
      e.preventDefault();
      if (!can_manage) {
        permissionDeniedClick();
      }
    }
    designClickEvent();
  };

  function handleSingleClick(id: number, index: number, shiftPressed: boolean) {
    let projectIndex = selectedProjects.indexOf(id);
    let templateIndex = selectedTemplates.indexOf(id);
    if (shiftPressed && selectedProjects.length > 0) {
      // let fromIndex: number = folders.findIndex((f: any) => f.id === selectedFolders[0]);
      // let tempFolders = fromIndex < index ? folders.splice(fromIndex, index) : folders.splice(index, fromIndex);
      // dispatch(addSelectedFolders(tempFolders.reduce((acc: any, val: any) => {
      //     if (selectedFolders.includes(val.id)) { return acc; }
      //     else {
      //         return [...acc, val.id]
      //     }
      // }, [])));
    } else {
      if (projectType === "template") {
        if (templateIndex > -1) {
          dispatch(
            setSelectedMyTemplates(
              selectedTemplates.filter((num) => num !== id)
            )
          );
        } else {
          dispatch(setSelectedMyTemplates([...selectedTemplates, id]));
        }
      } else {
        if (projectIndex > -1) {
          dispatch(
            setSelectedProject(selectedProjects.filter((num) => num !== id))
          );
        } else {
          dispatch(setSelectedProject([...selectedProjects, id]));
        }
      }
    }
  }

  function doDoubleClickAction(projectType: string, e: any) {
    if (projectType === "file") {
      setIsViewFile(true);
    } else if (
      projectType === "hpproject"
        ? render_status === "NOT_STARTED"
        : projectType !== "project"
    ) {
      e.preventDefault();
    } else {
      history.push(
        isPublicLink
          ? projectType === "project"
            ? `/cocreate/public/banner-detail/?banner=${id}`
            : `/cocreate/public/creative-automation/banner-detail/?banner=${id}`
          : projectType === "project"
          ? `/cocreate/banner-detail/?banner=${id}`
          : `/cocreate/creative-automation/detail/?banner=${id}`
      );
    }
  }
  const detectProjectType = (project) => {
    switch (project.file_type) {
      case "jpeg":
      case "jpg":
      case "png":
      case "gif":
      case "svg":
      case "ico":
      case "bmp":
      case "ps":
        // return "https://aiquire-creative-stg-backend.s3.ap-south-1.amazonaws.com" + `${project.rendered_file}?q=40&auto=format`;
        let url = !project.rendered_file.includes("gif")
          ? project.rendered_file +
            (detectQueryString(project.rendered_file) ? "&w=200" : "?w=200")
          : project.rendered_file || "";
        // Replace the old domain with the new domain in the URL
        // var newUrl = url.replace(oldDomain, newDomain);
        return url;
      // case "svg":
      //   return ImageFileSVG;
      case "psd":
        if (!project.psdUrl) {
          return ImageFileSVG;
        } else if (project.psdUrl.includes(".psd")) {
          return ImageFileSVG;
          // return ImageFileSVG;
        } else return project.psdUrl;

      default:
        const fileType = getFileTypeFromExtension(project.file_type);
        switch (fileType) {
          case "audio":
            return AudioFileSVG;

          case "video":
            return VideoFileSVG;

          case "presentation":
            return PPTFileSVG;

          case "internet":
            return InternetFileSVG;

          case "zip":
            return ZipFileSVG;

          case "word":
            return DocFileSVG;

          default:
            // return project.rendered_file.includes("gif") ? project.rendered_file : project.rendered_file + (detectQueryString(project.rendered_file) ? '&w=200' : '?w=200');
            return project.psdUrl ? project.psdUrl : ImageFileSVG;
        }
    }
  };

  const handleClick = (id: number, index: number, shiftPressed: boolean) => {
    timer = setTimeout(function() {
      if (!prevent) {
        handleSingleClick(id, index, shiftPressed);
      }
      prevent = false;
    }, 250);
  };

  function handleDoubleClick(projectType: string, e: any) {
    designClickEvent();
    clearTimeout(timer);
    prevent = true;
    doDoubleClickAction(projectType, e);
  }

  const showProgress: boolean =
    project.render_status === "INPROGRESS" ||
    (project.ready_status &&
      project.ready_status !== "COMPLETED" &&
      project.ready_status !== "FAILED");
  const notReadyOrNotRendered: boolean =
    (ready_status && ready_status === "FAILED") || render_status === "FAILED";
  const retry =
    project.render_status === "FAILED" ||
    (project.ready_status === "FAILED" && project.ready_failure_reason);
  const projectRendering =
    render_status === "INPROGRESS" ||
    (ready_status && ready_status !== "COMPLETED" && ready_status !== "FAILED");
  const previewFileType =
    data.type !== "image" &&
    data.type !== "image_set" &&
    data.type !== "variant_set" &&
    data.type !== "gif" &&
    (projectType === "project" || projectType === "hpproject");
  const VideoPreview = "3g2,3gp,avi,flv,mkv,mpg,mpeg,swf,vob,wmv";

  return (
    <div
      key={key}
      className={projectRendering ? "project-card rendering" : "project-card"}
    >
      <div
        className={`card noselect ${
          isSelected
            ? isDeletOverlay
              ? " selected delete-selected"
              : " selected"
            : ""
        }`}
      >
        <div
          className='card-body'
          onDoubleClick={(e: any) => {
            handleDoubleClick(projectType, e);
          }}
          onClick={(e: any) =>
            handleClick(project.id, projectIndex, e.shiftKey)
          }
        >
          {data.type === "video" ? (
            <video
              poster={VideoPreview.includes(data?.file_type) && VideoFileSVG}
            >
              <source
                src={
                  (!VideoPreview.includes(data?.file_type) &&
                    data.rendered_file + "#t=0.1") ||
                  ""
                }
              />
            </video>
          ) : (
            <Img
              className={
                projectType === "template"
                  ? "project-img template"
                  : projectType !== "file"
                  ? "project-img"
                  : "project-img file"
              }
              src={detectProjectType(project)}
              // style={
              //   !getIsTypeEditable(project?.file_type, project?.type) && {
              //     objectFit: "scale-down",
              //     objectPosition: "center center",
              //   }
              // }
              style={
                (!getIsTypeEditable(project?.file_type, project?.type) ||
                  (project.file_type === "psd" && !project.psdUrl) ||
                  project?.psdUrl?.includes(".psd")) && {
                  objectFit: "scale-down",
                  objectPosition: "center center",
                }
              }
            />
          )}
          {(projectType === "project" ||
            projectType === "hpproject" ||
            projectType === "template") && (
            <div className='detail'>
              {!showProgress && (
                <>
                  {/* display total count and type of project/hp */}
                  {type === "image_set" || type === "variant_set" ? (
                    <span className='image_set' data-tip='Multiple set project'>
                      <img src={MultipleSet} alt='' />
                      {(projectType === "template"
                        ? sizes.length > 0
                        : rendered_count > 0) && (
                        <span className='count'>
                          {projectType === "template"
                            ? sizes.length
                            : rendered_count}
                        </span>
                      )}
                    </span>
                  ) : (
                    type !== "image" && (
                      <div className='image-type'>
                        <span className='type'>{type}</span>
                      </div>
                    )
                  )}
                  {/* vertical/horizontal/square--->hp/design */}
                  {!isEmpty(sizes) &&
                    type !== "image_set" &&
                    type !== "variant_set" && (
                      <div className='size_container'>
                        <div
                          className={
                            sizes[0].height / sizes[0].width >
                            sizes[0].width / sizes[0].height
                              ? "horizontal project-size"
                              : sizes[0].width / sizes[0].height >
                                sizes[0].height / sizes[0].width
                              ? "vertical project-size"
                              : "square project-size"
                          }
                        ></div>
                      </div>
                    )}
                  {/* approval status--->hp/design */}
                  {status && (
                    <div
                      className='status'
                      style={{ background: getStatusColor(status) }}
                    >
                      <span className='status-info'>
                        {status === "approved"
                          ? "Approved"
                          : status === "rejected"
                          ? "Unapproved"
                          : status === "pending" && "Pending"}
                      </span>
                    </div>
                  )}
                </>
              )}
            </div>
          )}
          {(projectType === "project" ||
            projectType === "hpproject" ||
            projectType === "template") && (
            <div className='project-detail noselect'>
              {title && <p className='text'>{title}</p>}
              {projectType !== "template" && (
                <div className='project-info'>
                  <span className='by'>
                    {project?.created_by?.firstName && "By "}
                  </span>
                  <span className='created_by'>
                    {project?.created_by?.firstName
                      ? project.created_by.firstName
                      : ""}
                  </span>
                  <span className='by'>
                    {project?.created_by?.firstName && ", "}
                  </span>
                  {created_at && moment(created_at).format("MMM DD, YYYY")}
                </div>
              )}
              {/* { projectType !== 'template'&&<div className="project-info">{project.status}</div>} */}
            </div>
          )}
        </div>

        {showOverlay ? (
          <div
            onDoubleClick={(e: any) => {
              handleDoubleClick(projectType, e);
            }}
            onClick={(e: any) => {
              handleClick(project.id, projectIndex, e.shiftKey);
            }}
            className={`overlay noselect ${isEditEnabled ? "show-bg" : ""} ${
              isDeletOverlay ? "show delete-show" : ""
            } ${
              projectType === "project" || projectType === "hpproject"
                ? retry
                  ? "show half"
                  : !isDeletOverlay
                  ? "half"
                  : "project-delete show-bg"
                : projectType === "file"
                ? ""
                : "template-overlay"
            } ${isOpenMoreVisible ? "show" : ""}`}
          >
            {!isDeletOverlay ? (
              <div
                className='options'
                style={isPublicLink ? { float: "right" } : {}}
              >
                <div className='top-action'>
                  {!isPublicLink &&
                    (projectType === "template"
                      ? selectedTemplates.length < 1
                      : selectedProjects.length < 1) && (
                      <div className='more'>
                        <DropDownOption
                          componentCaller={componentCaller}
                          project={project}
                          projectType={projectType}
                          setPublicLinkDrawerProps={setPublicLinkDrawerProps}
                          setViewDetailsDrawerProps={setViewDetailsDrawerProps}
                          projectIndex={projectIndex}
                          scrollToTop={scrollToTop}
                          setIsDeleteOverlay={setIsDeleteOverlay}
                          isOpenMoreVisible={isOpenMoreVisible}
                          setIsOpenMoreVisible={setIsOpenMoreVisible}
                          setDropDownStep={setDropDownStep}
                          dropDownStep={dropDownStep}
                          setDrawerVisible={setDrawerVisible}
                          setCurrentProject={setCurrentProject}
                          isEditEnabled={isEditEnabled}
                          //  hasFailedProjects={hasFailedProjects} isMultipleDownloadDisable={isMultipleDownloadDisable}
                        />
                      </div>
                    )}
                  <div className='select'>
                    {allowSelection &&
                      (projectType === "template"
                        ? selectedTemplates.length > 0
                        : selectedProjects.length > 0) && (
                        <Checkbox
                          checked={isSelected}
                          onChange={(e: any) => {
                            handleClick(project.id, projectIndex, e.shiftKey);
                          }}
                        />
                      )}
                  </div>
                </div>
                {projectType !== "file" ? (
                  <div
                    className={`middle ${
                      projectType === "template"
                        ? "middle-template"
                        : retry
                        ? projectRendering
                          ? "middle-retry-loading"
                          : "middle-retry"
                        : ""
                    }`}
                    style={isPublicLink ? { top: "52%" } : {}}
                  >
                    {projectRendering ? (
                      <div className='progress'>
                        <Lottie
                          options={{
                            animationData: projectLoader,
                            loop: true,
                            autoplay: true,
                            rendererSettings: {
                              preserveAspectRatio: "xMidYMid slice",
                            },
                          }}
                          width={100}
                          height={70}
                        />
                        {(projectType === "project" ||
                          projectType === "hpproject") && (
                          <div className='project-progress-container'>
                            <>
                              {
                                <>
                                  <div className='count'>
                                    {rendered_count}/{rendered_count}
                                  </div>
                                  <Progress
                                    className='progress-bar'
                                    percent={percentage_completion}
                                  ></Progress>
                                </>
                              }
                            </>
                          </div>
                        )}
                      </div>
                    ) : (
                      <>
                        {retry && (
                          <div className='retry-container'>
                            {/* <img src={closeCircle} className="close"/>
                                        <div className="failed">render failed</div> */}
                            <div className='retry-view'>
                              <Tooltip
                                overlayStyle={{ fontWeight: "normal" }}
                                getPopupContainer={(trigger) =>
                                  trigger.parentNode as HTMLElement
                                }
                                placement='top'
                                title='Retry render'
                                content={
                                  <Button
                                    onClick={() => {
                                      if (!rendering) {
                                        if (projectType === "project") {
                                          setRendering(true);
                                          dispatch(
                                            renderImages(
                                              { projectId: id },
                                              (res: any, err: boolean) => {
                                                if (err)
                                                  openToast(
                                                    "error",
                                                    "Render failed"
                                                  );
                                                if (!err) {
                                                  banners[
                                                    projectIndex
                                                  ].render_status =
                                                    res.data.status ===
                                                    "NOT_STARTED"
                                                      ? "INPROGRESS"
                                                      : res.data.status;
                                                  dispatch(
                                                    setProjects(banners)
                                                  );
                                                  openToast(
                                                    "success",
                                                    "Render Successfull"
                                                  );
                                                }
                                                setRendering(false);
                                              }
                                            )
                                          );
                                        } else if (
                                          projectType === "hpproject"
                                        ) {
                                          setRendering(true);
                                          dispatch(
                                            generateCombinations(
                                              { projectId: id },
                                              (res: any, err: boolean) => {
                                                if (err)
                                                  openToast(
                                                    "error",
                                                    "Render failed"
                                                  );
                                                if (!err) {
                                                  banners[
                                                    projectIndex
                                                  ].render_status =
                                                    res.data.status ===
                                                    "NOT_STARTED"
                                                      ? "INPROGRESS"
                                                      : res.data.status;
                                                  dispatch(
                                                    setProjects(banners)
                                                  );
                                                  openToast(
                                                    "success",
                                                    "Render Successfull"
                                                  );
                                                }
                                                setRendering(false);
                                              }
                                            )
                                          );
                                        }
                                      }
                                    }}
                                    className='retry'
                                  >
                                    <ReloadOutlined />
                                  </Button>
                                }
                              ></Tooltip>
                              {isEditEnabled &&
                                projectType !== "template" &&
                                !isPublicLink &&
                                (projectType === "hpproject"
                                  ? render_status !== "NOT_STARTED"
                                  : true) && (
                                  <div className='btn'>
                                    <Link
                                      to={
                                        projectType === "project"
                                          ? `/cocreate/banner-detail/?banner=${id}`
                                          : `/cocreate/creative-automation/detail/?banner=${id}`
                                      }
                                      className='view-link'
                                    >
                                      <span className='txt'>View</span>
                                    </Link>
                                  </div>
                                )}
                            </div>
                            {isEditEnabled &&
                              (projectType === "project" ||
                                projectType === "hpproject") &&
                              !isPublicLink &&
                              getIsTypeEditable(data.file_type, data.type) && (
                                // <div className="btn">
                                //     <Link to={projectType === 'project'? (type === "image_set" ? `/cocreate/set/${project.id}` : `/cocreate/editor/${project.id}`)
                                //         : (project.render_status === 'NOT_STARTED'? (type === "image_set" ? `/cocreate/creative-automation/select/${id}`:
                                //         `/cocreate/creative-automation/editor/${id}`)  : `/cocreate/creative-automation/editor/${id}`)}
                                //         onClick={(e)=>{if(!can_manage || !isEditEnabled){ e.stopPropagation(); e.preventDefault(); permissionDeniedClick() }}}
                                //         className={(can_manage && isEditEnabled) ? "edit-link":"edit-link permission-denied-lock"}
                                //         >
                                //             <EditOutlined className="edit-icon"/>
                                //             <span className="txt">Open in editor</span>
                                //     </Link>
                                // </div>
                                <Tooltip
                                  placement='top'
                                  title={
                                    !isEditEnabled
                                      ? "Get in touch with our customer success manager to know more about our editing functionality."
                                      : ""
                                  }
                                  content={
                                    <div className='btn'>
                                      <Link
                                        to={
                                          projectType === "project"
                                            ? type === "image_set" ||
                                              type === "variant_set"
                                              ? `/cocreate/set/${project.id}`
                                              : `/cocreate/editor/${project.id}`
                                            : project.render_status ===
                                              "NOT_STARTED"
                                            ? type === "image_set" ||
                                              type === "variant_set"
                                              ? `/cocreate/creative-automation/select/${id}`
                                              : `/cocreate/creative-automation/editor/${id}`
                                            : `/cocreate/creative-automation/editor/${id}`
                                        }
                                        onClick={handleOpenInEditor}
                                        className={
                                          can_manage && isEditEnabled
                                            ? "edit-link"
                                            : "edit-link permission-denied-lock"
                                        }
                                      >
                                        <EditOutlined className='edit-icon' />
                                        <span className='txt'>
                                          Open in editor
                                        </span>
                                      </Link>
                                    </div>
                                  }
                                ></Tooltip>
                              )}
                          </div>
                        )}
                        {isEditEnabled &&
                          (projectType === "project" ||
                            projectType === "hpproject") &&
                          !notReadyOrNotRendered &&
                          !isPublicLink &&
                          getIsTypeEditable(data.file_type, data.type) && (
                            <Tooltip
                              placement='top'
                              title={
                                !isEditEnabled
                                  ? "Get in touch with our customer success manager to know more about our editing functionality."
                                  : ""
                              }
                              content={
                                <div className='btn'>
                                  <Link
                                    to={
                                      projectType === "project"
                                        ? type === "image_set" ||
                                          type === "variant_set"
                                          ? `/cocreate/set/${project.id}`
                                          : `/cocreate/editor/${project.id}`
                                        : project.render_status ===
                                          "NOT_STARTED"
                                        ? type === "image_set" ||
                                          type === "variant_set"
                                          ? `/cocreate/creative-automation/select/${id}`
                                          : `/cocreate/creative-automation/editor/${id}`
                                        : `/cocreate/creative-automation/editor/${id}`
                                    }
                                    onClick={handleOpenInEditor}
                                    className={
                                      can_manage && isEditEnabled
                                        ? "edit-link"
                                        : "edit-link permission-denied-lock"
                                    }
                                  >
                                    <EditOutlined className='edit-icon' />
                                    <span className='txt'>Open in editor</span>
                                  </Link>
                                </div>
                              }
                            ></Tooltip>
                          )}
                        {isEditEnabled && projectType === "template" && (
                          <div
                            className='btn'
                            onClick={(e) => {
                              if (!can_manage) {
                                permissionDeniedClick();
                              } else if (isEditEnabled) {
                                setLoader(true);
                                dispatch(
                                  createProjectFromTemplate(
                                    {
                                      templateId: project.id,
                                      title: project.title,
                                    },
                                    (res: any, err: boolean) => {
                                      setLoader(false);
                                      if (!err) {
                                        let project = res.data;
                                        history.push(
                                          project.type === "image_set" ||
                                            project.type === "variant_set"
                                            ? `/cocreate/set/${project.id}`
                                            : `/cocreate/editor/${project.id}`
                                        );
                                      }
                                    }
                                  )
                                );
                              }
                            }}
                          >
                            <Tooltip
                              placement='bottom'
                              title={
                                !isEditEnabled
                                  ? "Get in touch with our customer success manager to know more about our editing functionality."
                                  : ""
                              }
                              content={
                                <span>
                                  <Button
                                    className={
                                      isEditEnabled
                                        ? "download"
                                        : "download btn-disabled"
                                    }
                                    disabled={!isEditEnabled}
                                    type='default'
                                  >
                                    <span
                                      className={
                                        can_manage && isEditEnabled
                                          ? ""
                                          : "permission-denied-lock"
                                      }
                                    >
                                      Start with this
                                    </span>
                                  </Button>
                                </span>
                              }
                            ></Tooltip>
                          </div>
                        )}
                        {isEditEnabled &&
                          projectType !== "template" &&
                          (projectType === "hpproject"
                            ? render_status !== "NOT_STARTED"
                            : true) &&
                          !notReadyOrNotRendered && (
                            <>
                              <Tooltip
                                placement='bottom'
                                title={
                                  !isEditEnabled &&
                                  projectType === "hpproject" &&
                                  !isPublicLink
                                    ? "Get in touch with our customer success manager to know more about our editing functionality."
                                    : ""
                                }
                                content={
                                  <div className='btn'>
                                    <Link
                                      to={
                                        isPublicLink
                                          ? projectType === "project"
                                            ? `/cocreate/public/banner-detail/?banner=${id}`
                                            : `/cocreate/public/creative-automation/banner-detail/?banner=${id}`
                                          : projectType === "project"
                                          ? `/cocreate/banner-detail/?banner=${id}`
                                          : `/cocreate/creative-automation/detail/?banner=${id}`
                                      }
                                      onClick={(e: any) => {
                                        e.stopPropagation();
                                        if (
                                          !isEditEnabled &&
                                          projectType === "hpproject" &&
                                          !isPublicLink
                                        ) {
                                          e.preventDefault();
                                        }
                                        designClickEvent();
                                      }}
                                      className={
                                        !isEditEnabled &&
                                        projectType === "hpproject" &&
                                        !isPublicLink
                                          ? "view-link permission-denied-lock"
                                          : "view-link"
                                      }
                                    >
                                      <span className='txt'>View</span>
                                    </Link>
                                  </div>
                                }
                              ></Tooltip>
                            </>
                          )}
                      </>
                    )}
                  </div>
                ) : (
                  // preview image in library
                  <div className='btn file-btn'>
                    {/* <Button className="download" type="link" onClick={() => { handleDownload(project) }}><DownloadOutlined className="icon" />Download</Button> */}
                    {/* <Button onClick={(e:any) => {
                                        setIsViewFile(true);
                                    }} className="view-file" type="link">View file</Button> */}
                    {isViewFile && (
                      <div
                        id='mount-modal'
                        onClick={(e: any) => {
                          e.stopPropagation();
                        }}
                      >
                        <AntModal
                          visible={isViewFile}
                          onCancel={() => {
                            setIsViewFile(false);
                            prevent = true;
                          }}
                          centered
                          footer={null}
                          className='preview-modal'
                          title='Preview image'
                        >
                          <div className='img-container noselect'>
                            <Img
                              src={rendered_file || ""}
                              className='preview-img'
                            />
                          </div>
                        </AntModal>
                      </div>
                    )}
                  </div>
                )}
              </div>
            ) : (
              <div className='delete-overlay'>
                <div className='delete'>
                  Delete {projectType === "hpproject" ? "project" : projectType}
                  ?
                </div>
                <div className='btn-container'>
                  <Button
                    className='no'
                    onClick={(e: any) => {
                      e.stopPropagation();
                      setIsDeleteOverlay(false);
                    }}
                  >
                    No
                  </Button>
                  <Button
                    className='yes'
                    onClick={(e: any) => {
                      e.stopPropagation();
                      handleDelete(id);
                    }}
                    loading={loader}
                  >
                    Yes
                  </Button>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className='overlay'>
            <div className='select'>
              {allowSelection && can_manage && (
                <Checkbox
                  checked={isSelected}
                  onClick={() => {
                    createHyperProject && createHyperProject(data, setLoader);
                  }}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectCard;
